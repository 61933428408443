@import '../../styles/variables.scss';

.component {

    & > .text > p:not(:first-of-type) {
        margin-top: 0.5rem;
    }

    & > .alth3 {
        text-align: left;
        margin: 1rem 0;
        & span {
            float: right;
            font-size: 1.1rem;
        }
    }

    & > .codeButton {
        display: inline-block;
        & a {
            display: inline-block;
            color: $highlight;
            padding: 1rem;
        }
        & svg {
            transform: scale(1.5);
        }
        border-radius: 20px;
        background: transparent;
        border: 2px solid $highlight;
        transition: all 0.3s;
        &:hover {
            border: 2px solid transparent;
            background: $highlight;
            & svg {
                color: white;
            }
        }
    }

    & > h4 {
        margin-top: 3rem;
    }

    & > hr {
        margin-bottom: 1rem;
    }

    & .example {
        overflow-x: auto;
        padding: 1rem;
    }

    & > .text {
        width: 100%;
    }
    & > .tableContainer {
        overflow-x: auto;
        & > .propTable {
            width: 90%;
            margin: 0 auto;
            text-align: center;
            color: $light-page-text;
            overflow: scroll;
            letter-spacing: 1px;
    
            & > thead  {
                & > tr > th {
                    padding: 2rem;
                    letter-spacing: 1px;
                    background: rgb(233, 233, 233);
                }
            }
    
            & > tbody {
                & > tr > td {
                    padding: 0.5rem;
                    &  > p {
                        margin: 0.25rem 0;
                    }
                }
    
                & > tr:nth-of-type(2n + 1) {
                    background: rgb(250, 250, 250);
                }
    
                & > tr:nth-of-type(2n) {
                    background: rgb(238, 238, 238);
                }
            }
    
        }
    }
}


@media only screen and (max-width: 768px) {
    .propTable {
        width: 100%;
    }
}