@import '../../styles/variables.scss';

.toc {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1rem;
    & ul, ol {
        color: $highlight;
        letter-spacing: 2px;
        font-size: 1.1rem;
        list-style-type: none;

        & li {
            width: fit-content;
            margin-top: 0.5rem;
            border-bottom: 2px solid transparent;
            transition: all 0.3s;
            &:hover {
                border-bottom: 2px solid rgba($highlight, 0.3);
                cursor: pointer;
            }
        }
    }
}