@import '../../styles/variables.scss';

.container {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 2rem;
}

@media (max-width: $breakpoint-tablet) {
    .container {
        width: 100%;
    }
}