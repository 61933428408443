@import './variables.scss';

// Font
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600&display=swap');

// Global Styles

html {
  scroll-behavior: smooth;
}

.App {
  font-family: 'Raleway', sans-serif;
  transition: all 0.3s;
  a {
    color: $highlight;
  }

  p {
    margin: 0;
  }

  h1 {
    text-align: center;
    font-size: 2.25rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }

  h2 {
    text-align: center;
    font-weight: 400;
    letter-spacing: 1px;
  }

  h3 {
    text-align: center;
    margin: 1rem 0;
    letter-spacing: 1px;
    font-size: 1.5rem;
  }
}

// Themes

.dark {
  color: $dark-page-text;
  background: $dark-page-background;
}
  
  
.light {
  color: $light-page-text;
  background: $light-page-background;
}