.nav {
    padding: 1.5rem;
}

.content {
    width: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 768px) {
    .content {
        width: 100%;
    }
}