@import '../../styles/variables.scss';

.components {
    width: 60%;
    margin: 0 auto;
}

.text {
    width: 50%;
    margin: 0 auto 2rem auto;
    letter-spacing: 1px;
    font-size: 1.05rem;
    line-height: 150%;
    & a {
        text-decoration: none;
    }

    & > p:not(:first-of-type) {
        margin-top: 1rem;
    }
}

.toc {
    background: $light-page-background;
    color: $light-page-text;
    width: 30%;
    margin: 3rem auto;
    border-radius: 10px;
}

.componentContainer {
    &:not(:first-of-type) {
        margin-top: 4rem;
    }
}


@media only screen and (max-width: 768px) {
    .components, .text {
        width: 90%;
    }

    .toc {
        width: 90%;
    }
}