$light-colour: #A80000;
$light-page-background: #FFF;
$light-page-text: #555;

$dark-colour: #0000A8;
$dark-page-background: #333;
$dark-page-text: #FFF;

$highlight: #3590FF;

// Breakpoints

$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;