body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
*::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}
*::-webkit-scrollbar-thumb {
  background-image: linear-gradient(135deg, #00aeff, #a68eff);
  box-shadow: 3px 3px 13px 1px rgba(0,0,0,.5);
	-webkit-box-shadow: 3px 3px 13px 1px rgba(0,0,0,.5);
}
