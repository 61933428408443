@import '../../styles/variables.scss';

.outer {
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    border-radius: 10px;
    padding: 1rem;

    & > .carousel {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        transform: matrix(1, 0, 0, 1, 0, 0);
        user-select: none;
        padding: 40px;

        & > .carouselItem {
            flex: 0 0 100%;
            overflow: auto;
        
            &:not(:first-of-type) {
                margin-left: 80px;
            }
        }
    }

    & .nav {
        display: flex;
        padding: 1rem;
        justify-content: center;
        align-items: center;

        & > .button {
            width: 30px;
            height: 5px;
            background: white;
            margin: 0 0.25rem;
            cursor: pointer;
            transition: all 0.3s;
        }
        
        & > .current {
            background: $highlight;
        }
        
        & > .common {
            background: #b0b0b0;
        }
    }

    & .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
        cursor: pointer;
        z-index: 10;

        &.rightArrow {
            right: 0;
        }
    }
}