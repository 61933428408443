.frontEnd {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    text-align: center;

    &>* {
        flex: 1 1 20%;
        margin: 0.5rem;
    }
}