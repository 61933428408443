@import '../../styles/variables.scss';

.textarea {
    resize: none;
    font-family: sans-serif;
}

.completed {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & svg {
        transform: scale(5);
        margin: 4rem;
        transition: all 0.3s;
        fill: rgb(93, 204, 59);
    }

    & p, h4 {
        font-size: 110%;
    }

    & a {
        text-decoration: none;
        border-bottom: 2px solid transparent;
        transition: all 0.3s;
        &:hover {
            border-bottom: 2px solid $highlight;
        }
    }
}

.form {
    width: 30%;
    margin: 0 auto;
    padding: 1rem;
    background: $light-page-background;
    border-radius: 10px;
    & > *:focus {
        outline: none; 
    }

    & > .formGroup {
        width: 100%;
        &:first-of-type {
            display: flex;
            justify-content: space-between;
            & > input {
                flex: 0 0 47.5%;
            }
        }
        & > input, textarea {
            font-size: 1.05rem;
            width: 100%;
            padding: 1rem 0.5rem;
            box-sizing: border-box;
            margin: 1rem 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            transition: all 0.4s;
            &:not([type="submit"]) {
                border: none;
                border-bottom: 3px solid rgb(136, 136, 136);
                background: rgb(250, 250, 250);
                // On focus
                &:focus {
                    outline: none;
                    border-bottom: 3px solid $highlight;
                }
                // Present and invalid input
                &:not(:placeholder-shown):invalid {
                    border-bottom: 3px solid red;
                }
                // Present and valid input
                &:not(:placeholder-shown):valid {
                    border-bottom: 3px solid green;
                }
            }
            &[type="submit"] {
                text-align: center;
                background: transparent;
                border: 3px solid $highlight;
                color: $highlight;
                text-transform: uppercase;
                font-size: 1.1rem;
                letter-spacing: 1px;
                font-weight: 500;
                transition: all 0.3s;
                &:hover {
                    border: 3px solid transparent;
                    background: $highlight;
                    color: white;
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-tablet) {
    .form {
        width: 75%;
    }
}