@import "../../styles/variables.scss";

.section {
	.textblock {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: justify;
		width: 800px;
		margin: 0 auto;

		& > p {
			font-size: 1.4rem;
			line-height: 35px;
			margin-bottom: 1rem;
		}
	}
}

.inner {
	width: 60%;
	margin: 0 auto;
	padding: 1rem;
}

.avatar {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 3rem 0;

	& > img {
		width: 250px;
		height: 250px;
	}
}

.clipped {
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
	background: $highlight;
	padding: 2rem 0 15rem 0;

	& > .inner {
		color: white;
	}
}

.clippedAlt {
	clip-path: polygon(0 43%, 100% 0, 100% 100%, 0 100%);
	background: $highlight;
	padding: 10rem 0 2rem 0;

	& > .inner {
		color: white;
	}
}

.first {
	height: calc(100vh - 98px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.down {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 4rem;

	& > svg {
		transition: all 0.5s;
		transform: translateY(0);
	}

	& > svg:hover {
		animation: bounce 0.75s 2;
		cursor: pointer;
	}
}

.skills {
	margin: -7.5rem auto 0 auto;
	position: relative;
	z-index: 10;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	color: $light-page-text;
	max-width: 1500px;

	& > .skill {
		height: 600px;
		width: 30%;
		margin: 1rem;
		background: $light-page-background;
		box-shadow: 10px 10px 20px 5px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		position: relative;

		& > .skillHeader {
			padding-top: 1rem;

			& > .icon {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 5rem;
			}

			&:after {
				content: "";
				width: 50%;
				margin: 0 auto 1rem auto;
				height: 2px;
				background: $highlight;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		& > .skillText {
			padding: 1rem;
			text-align: justify;
			font-size: 1.1rem;
			font-family: sans-serif;

			& > p {
				margin-bottom: 0.5rem;
			}
		}

		& > .heading {
			text-align: center;
			font-size: 1.2rem;
			margin-top: 2rem;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $highlight;
		}

		& > .list {
			& ul {
				list-style-type: none;
				text-align: center;
				padding: 0;

				& li {
					margin: 0.5rem;
				}
			}
		}
	}
}

.info {
	display: flex;
	flex-wrap: wrap;

	& > .button {
		flex: 1 1 34%;
		font-size: 1.5rem;
		background: linear-gradient(to right, $highlight, $highlight);
		background-size: 0% 100%;
		background-repeat: no-repeat;
		margin: 1rem;
		padding: 4rem 0;
		border: 5px solid $highlight;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		transition: all 0.3s 0s;

		& > .icon {
			font-size: 2.5rem;
			margin-bottom: 1rem;
		}

		&:hover {
			background-size: 100% 100%;
			cursor: pointer;
			color: $dark-page-text;
		}
	}
}

@keyframes bounce {
	0% {
		transform: translateY(0);
	}

	25% {
		transform: translateY(20px);
	}

	100% {
		transform: translateY(0);
	}
}

@media (max-width: $breakpoint-tablet) {
	.skills {
		& > .skill {
			width: 90%;
		}
	}

	.inner {
		width: 90%;
	}

	.section {
		.textblock {
			width: 100%;
		}
	}

	.info {
		& > .button {
			flex: 1 1 100%;
		}
	}

	.down {
		display: none;
	}
}
